.card {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 565px;
	min-height: fit-content;
	max-height: calc(100vh - 70px);
	max-width: 100%;
	width: 360px;
	padding: 33px;
	margin: auto;
	border-radius: 4px;
	border: 1px solid #383838;
	background: rgba(12, 12, 12, 0.4);
	backdrop-filter: blur(2px);
	text-align: center;

	line-height: 15.62px;

	@media only screen and (max-width: (@screen-sm)) {
		padding: 20px;
	}

	.title {
		line-height: 26.04px;
	}
}


@import '/home/eff/app/creoplay-web/src/styles/variables.less';