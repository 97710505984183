* {
    font-family: @font-family;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.2px;
}

body {
    min-height: 100vh;
    width: 100% !important;
    font-family: @font-family;
    font-weight: 500;
    margin: 0;
    overflow: auto;
    overflow-x: hidden;

    @media only screen and (max-width: (@screen-xs)) {
        overflow-x: hidden;
    }
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
}

a {
    color: @primary-color;
    text-decoration: none;
    cursor: pointer;

    &.disabled {
        color: #858584;
        pointer-events: none;
        cursor: no-drop;
    }
}

.row {
    row-gap: 20px;
}

.cursor-default {
    cursor: default;
}

.cursor-pointer {
    cursor: pointer;
}

.pointer-events-none {
    pointer-events: none;
}

::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

::-webkit-scrollbar-track {
    background: @black;
}

::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 40px;
}

.sticky {
    position: -webkit-sticky;
    position: sticky;
}

.relative {
	position: relative;
}

.text-primary {
    color: @primary-color;
}

.text-success {
    color: #00da72;
}

.text-danger {
    color: #e21e1e;
}

.text-info {
    color: #0267ff;
}

.text-info-2 {
	color: @info-color;
}

.text-gradient {
    background: var(--Gradient-Blue, linear-gradient(270deg, #0267ff 0%, #004aba 90.5%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.ant-row {
    row-gap: 20px;
}

.notify {
    font-size: 16px !important;
    .anticon {
        font-size: 20px !important;
    }
}

.opacity-80 {
    opacity: 0.8;
}
