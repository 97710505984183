.skeleton {
	position: relative;
	width: 100%;
	border-radius: inherit;

	&::after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		animation: skeleton-loading 1s linear infinite alternate;
		border-radius: inherit;
	}
}

@keyframes skeleton-loading {
	0% {
		background-color: rgba(255, 255, 255, 0.1);
	}
	100% {
		background-color: rgba(255, 255, 255, 0.2);
	}
}


@import '/home/eff/app/creoplay-web/src/styles/variables.less';